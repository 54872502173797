.root {
	position: absolute;
	bottom: 0;
}

.item {
	flex: 0 0 auto;
}

.item + .item {
	margin-left: 2.4rem;
}
