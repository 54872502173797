.root {
	display: block;
	letter-spacing: 0.9px;
	font-family: 'Open Sans', sans-serif;
	font-size: var(--text-s);
	color: #101010;
	font-weight: normal;
}

.root:hover {
	text-decoration: underline;
}
