.root {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	transition: background-color 300ms ease;
	background-color: rgba(0, 0, 0, 0);
	-webkit-background-clip: text;
	display: flex;
	padding-bottom: 6px;
	margin-bottom: -16px;
}

.root:before {
	content: '';
	width: var(--edgePad);
	flex: 0 0 auto;
}

.root:after {
	content: '';
	width: var(--edgePad);
	flex: 0 0 auto;
}

.root:hover {
	background-color: rgba(0, 0, 0, 0.3);
}

.root::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.root::-webkit-scrollbar-thumb {
	background-color: inherit;
	border-radius: 6px;
}
