.selected:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: solid 6px #fcb040;
	box-sizing: border-box;
}
