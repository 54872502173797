.root {
	padding-top: var(--headerHeight);
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main {
	flex-grow: 1;
}
