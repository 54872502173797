:root {
	/* z-index layering */
	--headerDepth: 10;

	/* layout */
	--headerHeight: 6rem;
	--edgePad: 8rem;
	--imageWidthPoster: 194px;
	--imageWidthTile: 410px;

	/* breakpoints */
	--bpSmall: 960px;

	/* text sizes */
	--text-xxl: 7.2rem;
	--text-xl: 2.8rem;
	--text-l: 2.4rem;
	--text-ml: 2rem;
	--text-m: 1.6rem;
	--text-ms: 1.4rem;
	--text-s: 1rem;
}

@media screen and (max-width: 960px) {
	:root {
		--text-xxl: 5.4rem;
		--text-xl: 3.2rem;
		--text-l: 2.8rem;
		--text-ml: 2.4rem;
		--text-m: 2rem;
		--text-ms: 1.6rem;
		--text-s: 1.6rem;
	}
}
