:root {
	--hero-tile-offset: 6.8rem;
}

.root {
	width: 100%;
	height: 0;
	padding-top: calc(56.25% + var(--hero-tile-offset));
	position: relative;
}

.root:hover .arrow,
.root:focus-within .arrow {
	opacity: 1;
}

.root:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: calc(100% - var(--hero-tile-offset));
	background-color: #000;
}

.wide {
	padding-top: 0;
	height: calc(100vh - 2.4rem - var(--headerHeight));
}

.image {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: calc(100% - var(--hero-tile-offset));
	object-fit: cover;
	object-position: 100% 0;
}

.overlay {
	background-image: linear-gradient(to bottom, #00000000 0%, #000000);
}

.info {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(calc(-50% - var(--hero-tile-offset)));
	display: flex;
	padding: 0 2.8rem;
	align-items: center;
}

.arrow {
	transition: opacity 300ms ease-in;
	opacity: 0;
	color: #fff;
}

.arrow:hover {
	color: rgba(255, 255, 255, 0.8);
}

.fadeIn {
	animation: imageFadeIn 500ms ease-in-out forwards;
}

.fadeOut {
	animation: imageFadeOut 500ms ease-in-out forwards;
}

@keyframes imageFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes imageFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media screen and (max-width: 960px) {
	.info {
		top: 40%;
	}
}
