.root {
	width: 100%;
	background-color: #fff;
	box-shadow: 0 -2px 4px 0 #00000017;
	padding: 3.2rem var(--edgePad) 24px;
}

.socialLink {
	display: inline-block;
}

.socialLink + .socialLink {
	margin-left: 1rem;
}

.links {
	margin-top: 2.4rem;
	display: inline-block;
}

.links + .links {
	margin-left: 6.3rem;
}
