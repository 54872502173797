.root {
	margin: 0 6.7rem;
	color: #fff;
	flex: 1 0 auto;
	position: relative;
}

.badge {
	position: absolute;
	top: 0;
	transform: translateY(-100%);
	font-size: var(--text-l);
	color: #fcb040;
	margin: 0;
	text-transform: uppercase;
}

.title {
	font-size: var(--text-xxl);
	text-transform: uppercase;
	margin: 0;
}

.description {
	margin: 10px 0 0;
	position: absolute;
	letter-spacing: 1.79px;
	font-size: var(--text-l);
}

@media screen and (min-width: 960px) {
	.title {
		max-width: 50vw;
	}
	.description {
		max-width: 50vw;
	}
}
