.root {
	position: fixed;
	top: 0;
	width: 100%;
	height: var(--headerHeight);
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	padding: 0 2.8rem 0 var(--edgePad);
	z-index: var(--headerDepth);
}

.left {
	flex-grow: 1;
}

.button {
	text-transform: uppercase;
	letter-spacing: 1.25px;
	padding: 1rem 2rem;
}

.profileButton {
	display: flex;
	align-items: center;
	justify-self: end;
}

.profileButtonLabel {
	margin-left: 1.6rem;
}

.signUpButton {
	margin-left: 1.2rem;
	border: solid 1px #2f2e41;
	border-radius: 1.8rem;
}
