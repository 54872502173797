.title {
	margin-left: var(--edgePad);
	font-size: var(--text-l);
}

.item {
	flex: 0 0 auto;
}

.item + .item {
	margin-left: 2.4rem;
}

.placeholderTitle {
	width: 32rem;
}
