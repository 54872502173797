html {
	text-size-adjust: 100%;
	font-size: 10px;
	background: #fff;
}

body {
	margin: 0;
	font-family: 'Raleway', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #3b3b3b;
	font-weight: 600;
	font-size: var(--text-ms);
}

* {
	box-sizing: border-box;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	margin: 0;
	padding: 0;
}

button {
	background: none;
	border: none;
	appearance: none;
}

a {
	color: inherit;
	text-decoration: none;
}

a:hover,
button:hover {
	color: #000;
	cursor: pointer;
}

/* hide focus outline when using mouse/touch, show when interacting with keyboard */
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}

.image-poster {
	/* aim to fit 5 and a half images on screen */
	width: calc((100% - 2 * var(--edgePad)) / 5.5);
	max-width: var(--imageWidthPoster);
	min-width: calc(var(--imageWidthPoster) * 0.66);
}

.image-tile {
	/* aim to fit 3 and a half images on screen */
	width: calc((100% - 2 * var(--edgePad)) / 3.5);
	max-width: var(--imageWidthTile);
	min-width: calc(var(--imageWidthTile) * 0.5);
}

.block-loader {
	background: #606774;
	animation: blockLoader 1.8s ease-in-out infinite;
}

@keyframes blockLoader {
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0.2;
	}
}

@media screen and (max-width: 960px) {
	html {
		font-size: 6px;
	}
}
