.root {
	position: relative;
	background-color: #ededed;
	background-position: bottom right;
	background-repeat: no-repeat;
}

.root:first-child {
	margin-bottom: 0;
}

.dark.root {
	background-color: #27272c;
}

.got.root {
	background-color: #29283f;
}

.backgroundFit {
	background-size: auto 100%;
}

.content {
	width: 100%;
	padding: 4.8rem var(--edgePad) 3.8rem;
}

.title {
	font-size: var(--text-xl);
	margin-bottom: 6px;
	margin-top: 0;
	color: #000;
}

.dark .title,
.got .title {
	color: #fff;
}

.message {
	font-weight: 500;
	line-height: 1.56;
	font-size: var(--text-m);
	color: #3b3b3b;
	max-width: 50%;
	margin: 0;
}

.dark .message,
.got .message {
	color: #fff;
}

.actionButton {
	padding: 0 2rem;
	height: 3.8rem;
	border-radius: 2rem;
	background-color: #2f2e41;
	color: #fff;
	letter-spacing: 1.25px;
	text-transform: uppercase;
}

.dark .actionButton {
	background-color: #e9ac48;
	color: #000;
}

.got .actionButton {
	background-color: #fff;
	color: #2f2e41;
}

.actionRight {
	position: absolute;
	right: 6.6rem;
	bottom: 3.2rem;
}

.actionLeft {
	margin-top: 3rem;
}

.light .actionButton:hover {
	color: #fff;
}

.got .actionButton:hover {
	color: #000;
}

.dismissButton {
	position: absolute;
	top: 2.8rem;
	right: 2.8rem;
}

.dark .dismissButton,
.got .dismissButton {
	color: #fff;
}
