.root {
	position: relative;
	background: #000;
}

.root:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0;
	transition: opacity 200ms ease-in;
}

.root:hover:after,
.root:focus:global(.focus-visible):after {
	opacity: 0.3;
}

.image {
	display: block;
	width: 100%;
}
