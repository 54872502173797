.root {
	position: relative;
	display: flex;
	width: calc(100% - var(--edgePad) * 2);
	margin-left: var(--edgePad);
	margin-right: var(--edgePad);
	background-color: #000;
	color: #fff;
}

.got {
	background-color: #0f1723;
}

.background {
	position: absolute;
	right: 0;
	height: 100%;
	max-width: 100%;
	object-fit: cover;
	pointer-events: none;
}

.character {
	position: absolute;
	top: -3.2rem;
	right: 0;
	height: calc(100% + 3.2rem);
	max-width: 60%;
	object-fit: cover;
	object-position: left;
	pointer-events: none;
}

.overlay {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 60%;
	background-image: linear-gradient(to bottom, #0e407400, #070b0e);
}

.left {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	width: 30rem;
	margin: 6rem 7.2rem 0;
	max-width: 30%;
}

.logo {
	width: 100%;
}

.tagline {
	max-width: 100%;
	padding: 1rem;
	text-align: center;
	flex: 1 0 auto;
	font-size: var(--text-m);
}

.thumbs {
	width: 100%;
}

.right {
	flex: 0 1 auto;
	z-index: 1;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	margin-right: 12rem;
	padding-bottom: 9rem;
	max-width: 56rem;
}

.title {
	margin-bottom: 2.2rem;
	font-size: 4.4rem;
}

.description {
	margin-bottom: 2.7rem;
	font-size: var(--text-m);
}

.buttons {
	display: flex;
}

.watchButton {
	padding: 0 2rem;
	height: 3.8rem;
	border-radius: 2rem;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	background-color: #e9ac48;
	color: #000;
	margin-right: 4rem;
}

.saveButton {
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: #fff;
	display: flex;
	align-items: center;
}

.saveButton:hover,
.saveButton:focus {
	color: #fff;
}

.saveButtonIcon {
	margin-right: 1.6rem;
}
